//import axios from "axios";
import { cloneDeep } from "lodash";
//import { setReportAppStatus } from "reducers/appReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  selectedTab: [-1, -1],
  selectedMdhApp: 0,
  mdhTabs: [],
  tabs: [],
  searchTabs: [],
  getNewDisplayRpts: false
};

/* -------------------- ACTION TYPES -------------------- */
const SET_TAB = "SET_TAB";
const ADD_TAB = "ADD_TAB";
const REMOVE_TAB = "REMOVE_TAB";
const ADD_CHILD = "ADD_CHILD";
const REMOVE_CHILD = "REMOVE_CHILD";
const SET_SEARCH_TAB = "SET_SEARCH_TAB";
const GET_DISP_RPTS = "GET_DISP_RPTS";
const SET_MDH_APP = "SET_MDH_APP";
const REMOVE_NEW_SAVE_TAB = "REMOVE_NEW_SAVE_TAB";

/* -------------------- ACTION CREATORS -------------------- */
const setTab = tab => {
  return { type: SET_TAB, payload: tab };
};
const setMDHApp = app => {
  return { type: SET_MDH_APP, payload: app };
};
const addTab = tab => {
  return { type: ADD_TAB, tab: tab };
};
const removeTab = tab => {
  return { type: REMOVE_TAB, tab: tab };
};
export const removeNewTab = (rpt, target) => {
  return { type: REMOVE_NEW_SAVE_TAB, target, rpt };
};

const addActiveChild = (tab, child, altType, customGroup) => {
  return { type: ADD_CHILD, tab: tab, child: child, altType, customGroup };
};
const removeSpecifiedChild = (tab, child) => {
  return { type: REMOVE_CHILD, tab: tab, child: child };
};
const setSearchTab = (tab, filter) => ({
  type: SET_SEARCH_TAB,
  payload: tab,
  filter
});
const getNewDisplayRpts = status => ({
  type: GET_DISP_RPTS,
  payload: status
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const setSelectedTab = curTab => {
  return dispatch => {
    //dispatch(setReportAppStatus(false));
    dispatch(setTab(curTab));
    dispatch(getNewDisplayRpts(true));
  };
};
export const onlySetTab = curTab => {
  return dispatch => {
    dispatch(setTab(curTab));
  };
};
export const setSelectedMdhApp = curApp => {
  return dispatch => {
    dispatch(setMDHApp(curApp));
  };
};
export const setGetNewDisplayRpts = status => {
  return dispatch => {
    dispatch(getNewDisplayRpts(status));
  };
};
export const addTabToNav = tab => {
  return dispatch => {
    dispatch(addTab(tab));
    dispatch(getNewDisplayRpts(true));
  };
};
export const removeTabFromNav = (tab, index) => {
  return dispatch => {
    dispatch(removeTab(tab));
  };
};
export const addChild = (tab, child, altType, customGroup) => {
  return dispatch => {
    dispatch(addActiveChild(tab, child, altType, customGroup));
  };
};
export const removeChild = (tab, child) => {
  return dispatch => {
    dispatch(removeSpecifiedChild(tab, child));
  };
};
export const filterTab = (filter, tab, selectTab) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchTab(tab, filter));
      // dispatch(setCollapsedFolders(true));
      // dispatch(setDoCollapse(true));
    } else {
      let newTab = cloneDeep(tab);

      dispatch(
        setSearchTab(
          newTab.children.map(child => {
            if (child.children !== undefined && child.children.length > 0) {
              child.children = child.children.filter(group => {
                if (group.name !== undefined) {
                  return group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                }
                return group.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
              });
            } else {
              if (child.name.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
                return null;
              }
            }

            return child;
          }),
          filter
        )
      );
      // dispatch(setCollapsedFolders(false));
      // dispatch(setDoCollapse(true));
    }
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TAB:
      return { ...state, selectedTab: action.payload };
    case SET_MDH_APP:
      return { ...state, selectedMdhApp: action.payload };
    case ADD_TAB:
      let newTabs = state.tabs;
      let newSelected = state.selectedTab;
      let newTab = action.tab;
      let tabObj = {};
      let newSelectedMdh = state.selectedMdh;
      let newMdhTabs = cloneDeep(state.mdhTabs);
      if (action.tab.type === "mdh") {
        action.tab.index = state.tabs.length;
        newMdhTabs.push(action.tab);
        newSelectedMdh = action.tab.mdmIdx;
      }
      if (action.tab.type === "customApplication") {
        let findApp;
        if (action.tab.workspaces !== undefined) {
          findApp = action.tab.workspaces.filter(item => {
            return item._id === action.tab.workspaceId;
          });
          findApp = findApp[0];
        } else {
          findApp = {
            applications: action.tab.applications,
            groupings: action.tab.groupings,
            order: action.tab.order,
            icon: action.tab.icon
          };
        }
        if (findApp !== undefined) {
          let nChild = [];

          if (findApp.groupings !== undefined && findApp.groupings.length > 0) {
            nChild = [...findApp.groupings];
          }
          if (findApp.applications !== undefined && findApp.applications.length > 0) {
            for (var nApp of findApp.applications) {
              nApp.type = nApp.title;
              nChild.push(nApp);
            }
          }
          if (action.tab.children) {
            action.tab.children.forEach(item => {
              if (item.title === "Files") {
                nChild.push(item);
              } else if (item.type === "folder") {
                item.children.forEach(child => {
                  if (child.type === "file") {
                    nChild.push(child);
                  }
                });
              } else if (item.type === "tableau") {
                nChild.push(item);
                // if (item.children !== undefined) {
                //   item.children.forEach(child => {
                //     if (child.selected === true) {
                //       nChild.push(child);
                //     }
                //   });
                // }
              } else {
                nChild.push(item);
              }
            });
          } else {
            action.tab.children = [];
          }

          var c = [];
          if (findApp.order) {
            findApp.order.forEach(el =>
              c.push(
                nChild.find(e => {
                  if (e.type === "Files") {
                    return e.type === el;
                  } else if (e.type === "grouping") {
                    return e.id === el;
                  } else if (e.type === "powerBI") {
                    return e._id === el;
                  } else if (e.type === "tableau" || e.type === "staticContent") {
                    return e._id === el;
                  }
                  return false;
                })
              )
            );
          } else {
            c = nChild;
          }
          c = c.filter(item => {
            return item !== undefined;
          });
        }
        action.tab.children = c;
      }

      if (state.tabs.length === 0) {
        // newTabs.index = [0, 0];
        if (action.tab.workspaceName !== undefined) {
          tabObj = {
            workspaceName: action.tab.workspaceName,
            workspaceId: action.tab.workspaceId,
            activeChildren: action.tab.activeChildren,
            children: action.tab.children,
            icon: action.tab.icon,
            alias: action.tab.alias,
            index: [0, 0]
          };
          newTabs.push(tabObj);
        } else {
          action.tab.index = [0, 0];
          newTabs.push(action.tab);
        }
        if (action.tab.activeChildren !== undefined && action.tab.activeChildren.length > 0) {
          if (
            action.tab.activeChildren[0].activeChildren !== undefined &&
            action.tab.activeChildren[0].activeChildren.length > 0
          ) {
            if (
              action.tab.activeChildren[0].activeChildren[0].activeChildren !== undefined &&
              action.tab.activeChildren[0].activeChildren[0].activeChildren.length > 0
            ) {
              newSelected = [0, 0, 0, 0];
            } else {
              newSelected = [0, 0, 0];
            }
          } else {
            newSelected = [0, 0];
          }
        } else {
          newSelected = [0, -1];
        }
      } else {
        let idx;

        if (action.tab.workspaceName !== undefined) {
          //check to make sure the parent doesnt already exist
          idx = state.tabs
            .map(tab => {
              return tab.workspaceId;
            })
            .indexOf(action.tab.workspaceId);
          if (idx === -1) {
            //parent does not exist
            let ac = [];
            if (action.tab.activeChildren !== undefined) {
              ac = action.tab.activeChildren;
            }
            newTab.index = [state.tabs.length, 0];

            newTabs.push({
              workspaceName: action.tab.workspaceName,
              workspaceId: action.tab.workspaceId,
              activeChildren: ac,
              children: action.tab.children,
              icon: action.tab.icon,
              alias: action.tab.alias
            });
            if (ac.length > 0 && ac[0].activeChildren !== undefined) {
            } else {
              newSelected = [state.tabs.length - 1, 0];
            }
          } else {
            //parent does exist ... now check if child exists
            if (state.tabs[idx].activeChildren === undefined) {
              state.tabs[idx].activeChildren = [];
            }
            let childIdx = state.tabs[idx].activeChildren
              .map(child => {
                return child.id;
              })
              .indexOf(action.tab.id);
            if (childIdx === -1) {
              if (action.tab.path !== undefined && action.tab.path.split("/").length === 2) {
                newTab.index = [idx, -1];
                newSelected = [idx, -1];
              } else {
                //child does not exist ... add it
                let childIndex = state.tabs[idx].activeChildren.length;
                if (action.tab.type === "customApplication") {
                  newTab.index = [idx, -1];

                  newSelected = [idx, -1];
                } else {
                  newTab.index = [idx, childIndex - 1];
                  // state.tabs[idx].activeChildren.push(newTab);
                  newSelected = [idx, childIndex - 1];
                }
              }
            } else {
              newSelected = [idx, childIdx];
            }
          }
        } else {
          //Check to make sure the tab doesnt already exist
          idx = state.tabs
            .map(tab => {
              return tab.id;
            })
            .indexOf(action.tab.id);
          if (idx === -1) {
            //push the new tab to the end and select it
            action.tab.index = [state.tabs.length, 0];
            newTabs.push(action.tab);
            newSelected = [state.tabs.length - 1, 0];
          } else {
            newSelected = [idx, 0];
          }
        }
      }

      return {
        ...state,
        tabs: newTabs,
        searchTabs: newTabs,
        selectedTab: newSelected,
        mdhTabs: newMdhTabs,
        selectedMdhApp: newSelectedMdh
      };
    case REMOVE_NEW_SAVE_TAB:
      let nSelected = state.selectedTab;
      console.log(action.target);
      console.log(action.rpt);
      action.rpt.title = action.rpt.name;
      let nTabs = state.tabs;
      //find the proper folder
      const ws = state.tabs.filter(item => {
        return item.workspaceId === action.rpt.workspaceId;
      });
      let parentFolder;
      if (ws.length === 1) {
        if (action.target.split("/").length === 3) {
          let parentIdx = -1;
          parentFolder = ws[0].children.filter((item, idx) => {
            if (item.name === action.target.split("/")[1]) {
              parentIdx = idx;
            }
            return item.name === action.target.split("/")[1];
          });
          nSelected[1] = parentIdx;
          parentFolder = parentFolder[0];
          if (
            parentFolder.activeChildren !== null &&
            parentFolder.activeChildren !== undefined &&
            parentFolder.activeChildren.length > 0
          ) {
            parentFolder.activeChildren.push(action.rpt);
            nSelected[2] = parentFolder.activeChildren.length;
          } else {
            parentFolder.activeChildren = [action.rpt];
            nSelected[2] = 0;
          }
        } else if (action.target.split("/").length >= 4) {
          let parentIdx = -1;

          let subParentFolder;
          parentFolder = ws[0].children.filter((item, idx) => {
            if (item.name === action.target.split("/")[1]) {
              parentIdx = idx;
            }
            return item.name === action.target.split("/")[1];
          });
          subParentFolder = parentFolder.children.filter((item, idx) => {
            return item.name === action.target.split("/")[1];
          });
          subParentFolder = subParentFolder[0];
          if (
            parentFolder.activeChildren !== null &&
            parentFolder.activeChildren !== undefined &&
            parentFolder.activeChildren.length > 0
          ) {
            parentFolder.activeChildren.push(subParentFolder);
            nSelected[2] = parentFolder.activeChildren.length;
          } else {
            parentFolder.activeChildren = [subParentFolder];
            nSelected[2] = 0;
          }
          if (
            subParentFolder.activeChildren !== null &&
            subParentFolder.activeChildren !== undefined &&
            subParentFolder.activeChildren.length > 0
          ) {
            subParentFolder.activeChildren.push(action.rpt);
            nSelected[3] = subParentFolder.activeChildren.length;
          } else {
            subParentFolder.activeChildren = [action.rpt];
            nSelected[3] = 0;
          }

          nSelected[1] = parentIdx;
          parentFolder = parentFolder[0];
        }
        if (parentFolder !== null) {
          if (state.tabs !== undefined && state.tabs[state.tabs.length - 1] !== undefined) {
            if (state.tabs[state.tabs.length - 1].activeChildren.length >= 1) {
              nTabs[state.tabs.length - 1].activeChildren.pop();
              nTabs[state.tabs.length - 1].activeChildren.push(parentFolder);
            }

            return {
              ...state,
              tabs: nTabs,
              searchTabs: nTabs,
              selectedTab: nSelected
            };
          }
        }
      }

      break;
    case REMOVE_TAB:
      let removeTabs = [];
      let removeMdh = [];
      let tabs = state.tabs;

      if (action.tab.index !== undefined && state.tabs !== undefined && state.tabs[action.tab.index] !== undefined) {
        if (state.tabs[action.tab.index].type === "mdh") {
          removeTabs = state.tabs.filter(tab => {
            if (tab.type === "mdh") {
              return tab.appId !== action.tab.ID;
            } else {
              return true;
            }
          });
          removeMdh = state.mdhTabs.filter(tab => {
            return tab.appId !== action.tab.ID;
          });
        } else if (state.tabs[action.tab.index].children.length > 1) {
          removeTabs = state.tabs.filter(tab => {
            return tab.workspaceId !== action.tab.workspaceId;
          });
          removeTabs = removeTabs.map(item => {
            item.index[1] = item.index[1] - 1;
            return item;
          });
          tabs[action.tab.index].children = removeTabs;

          return { ...state, tabs: tabs };
        } else if (state.tabs[action.tab.index].children.length === 1) {
          removeTabs = state.tabs.filter(tab => {
            return tab.workspaceId !== action.tab.id.split("+")[0];
          });
        }
      } else {
        //create a new array with the tab you want removed filtered out

        removeTabs = state.tabs.filter(tab => {
          if (action.tab.workspaceId === undefined) {
            return tab.type !== action.tab.type;
          }
          return tab.workspaceId !== action.tab.workspaceId;
        });
      }

      return {
        ...state,
        tabs: removeTabs,
        mdhTabs: removeMdh,
        searchTabs: removeTabs
      };

    case ADD_CHILD:
      let selectedTab = state.selectedTab;
      let addChildTabs;

      if (state.tabs.index !== undefined) {
        selectedTab[0] = state.tabs.index[0];
      } else {
        state.tabs.forEach((item, idx) => {
          if (item.workspaceId === action.tab.workspaceId) {
            selectedTab[0] = idx;
          }
        });
      }

      if (state.tabs[state.selectedTab[0]].workspaceId !== action.tab.workspaceId) {
        state.tabs.forEach((item, idx) => {
          if (item.workspaceId === action.tab.workspaceId) {
            selectedTab[0] = idx;
          }
        });
      }

      addChildTabs = state.tabs.map(tab => {
        if (tab.workspaceId === action.tab.workspaceId) {
          if (action.child !== undefined && (action.child.title === undefined || action.child.title === "")) {
            action.child.title = action.child.name;
          }
          if (tab.activeChildren === undefined) {
            tab.activeChildren = [];
          }
          let aChildIds = [];
          tab.activeChildren.forEach(item => {
            //PBI Folders do not have ids

            if (item.id === undefined) {
              aChildIds.push(item.path);
              if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
                item.activeChildren.forEach(cItem => {
                  if (cItem.activeChildren !== undefined && cItem.activeChildren.length > 0) {
                    cItem.activeChildren.forEach(gItem => {
                      aChildIds.push(gItem.id);
                    });
                  }
                  if (cItem.id !== undefined) {
                    aChildIds.push(cItem.id);
                  }

                  aChildIds.push(cItem.path);
                });
              }
            } else if (item.id.includes("+")) {
              aChildIds.push(item.id.split("+")[1]);
            } else if (item.type === "grouping") {
              if (item.activeChildren !== undefined) {
                item.activeChildren.forEach(child => {
                  aChildIds.push(child.id);
                });
              }
              aChildIds.push(item.id);
            } else {
              aChildIds.push(item.id);
            }
          });

          let childId = "";

          if (action.child === undefined) {
          } else if (action.child.id) {
            childId = action.child.id;
          } else {
            childId = action.child._id;
          }

          if (childId === undefined || childId === "") {
            if (action.child.path === undefined) {
              childId = action.child.paths[0];
            } else {
              childId = action.child.path;
            }
          } else if (childId.includes("+")) {
            childId = childId.split("+")[1];
          }
          let runSelectedIndex = true;
          if (!aChildIds.includes(childId)) {
            if (
              action.child.type === undefined ||
              action.child.type === "grouping" ||
              (action.child.fileType !== undefined && action.child.fileType === "folder")
            ) {
              if (action.child.path !== undefined && action.child.path.split("/").length > 3) {
                let newChild = cloneDeep(action.child);
                // newChild.children = [];
                if (tab.activeChildren[state.selectedTab[1]].activeChildren === undefined) {
                  tab.activeChildren[state.selectedTab[1]].activeChildren = [newChild];
                } else {
                  tab.activeChildren[state.selectedTab[1]].activeChildren.push(newChild);
                }
              } else if (action.child.path && action.child.path.split("/").length > 2) {
                let newChild = cloneDeep(action.child);
                // newChild.children = [];
                tab.activeChildren.push(newChild);
              } else {
                let newChild = cloneDeep(action.child);
                // newChild.children = [];
                tab.activeChildren.push(newChild);
              }
            } else if (
              action.child.type === "fileGroupings" ||
              action.child.type === "tableauGroupings" ||
              (action.altType !== undefined && action.altType.match(/Grouping/i))
            ) {
              let parent = tab.children.filter(item => {
                if (item.children === undefined) {
                  return false;
                }
                var gChildMatch = [];
                let childMatch = item.children.filter(child => {
                  if (action.child.rptId === undefined) {
                    if (
                      action.child.path !== undefined &&
                      action.child.path.split("/").length > 3 &&
                      child.path.split("/").length > 3
                    ) {
                      if (child.children === undefined) {
                        console.log("Child chidren are undefined");
                        console.log(child);
                      } else {
                        gChildMatch = child.children.filter(gChild => {
                          return gChild.id === action.child.id;
                        });
                      }
                      return gChildMatch.length > 0;
                    }
                    return child.id === action.child.id;
                  } else {
                    if (item.type.match(/Grouping/i)) {
                      return child.rptId === action.child.rptId;
                    } else {
                      return child.rptId === action.child.rptId && item.title === action.child.workspaceName;
                    }
                  }
                });
                return childMatch.length > 0 || gChildMatch.length > 0;

                //return item.workspaceId === action.child.workspaceId;
              });
              var nParent = [];

              if (parent.length > 1) {
                parent.forEach(child => {
                  if (action.child.workspaceId === child.id) {
                    nParent = cloneDeep(child);
                  } else if (action.child.groupId === child.id) {
                    nParent = cloneDeep(child);
                  }
                });
              } else {
                nParent = cloneDeep(parent[0]);
              }

              if (parent === undefined || parent.length === 0) {
                if (tab.activeChildren === undefined) {
                  tab.activeChildren = [action.child];
                } else {
                  tab.activeChildren.push(action.child);
                }
              } else {
                parent = nParent;
                let pIdx = -1;
                if (action.child.workspaceId === undefined) {
                  action.child.workspaceId = tab.workspaceId;
                }
                let parentISActive = cloneDeep(tab.activeChildren).filter((item, idx) => {
                  if (item.type === undefined) {
                    if (item.workspaceId === undefined) {
                      let check = action.child.path.split("/");
                      //if the length is greater than three then its a subreport
                      if (check.length > 3) {
                        if (item.title === check[1]) {
                          pIdx = idx;
                        }
                        return item.title === check[1];
                      } else {
                        if (item.title === action.child.workspaceName) {
                          pIdx = idx;
                        }
                        return item.title === action.child.workspaceName;
                      }
                    }
                    let ptPathArr = action.child.path.split("/");
                    let ptPath = ptPathArr[0] + "/" + ptPathArr[1] + "/";
                    if (item.path === ptPath) {
                      pIdx = idx;
                    }
                    if (ptPathArr.length < 3) {
                      return item.workspaceId === action.child.workspaceId;
                    } else {
                      return false;
                    }
                  }
                  if (!item.type.match(/Grouping/i)) {
                    return false;
                  }

                  if (action.child.groupId !== undefined) {
                    if (item.id === action.child.groupId) {
                      pIdx = idx;
                    }
                    return item.id === action.child.groupId;
                  }
                  if (item.workspaceId === undefined || action.child.workspaceId.length === 32) {
                    pIdx = idx;
                    return item.id === action.child.workspaceId;
                  } else if (item.workspaceId === action.child.workspaceId) {
                    pIdx = idx;
                  }
                  return item.workspaceId === action.child.workspaceId;
                });
                let gParentPathArr = [];
                let parentPath = "";
                if (action.child.path !== undefined) {
                  gParentPathArr = action.child.path.split("/");
                  parentPath = gParentPathArr[0] + "/" + gParentPathArr[1] + "/" + gParentPathArr[2] + "/";
                }
                if (parentISActive !== undefined && parentISActive.length > 0) {
                  //the group currently exists in the nav menu ... add to it
                  //check if the report is already active
                  if (tab.activeChildren[pIdx].activeChildren === undefined) {
                    tab.activeChildren[pIdx].activeChildren = [];
                  }
                  let childActive = cloneDeep(tab.activeChildren[pIdx].activeChildren).filter(child => {
                    if (action.child.type === "powerBI" && action.child.path.split("/").length > 3) {
                      //there is a grand child involved here
                      return child.path === parentPath;
                    }

                    if (child.id === undefined) {
                      return action.child.path === child.path;
                    } else if (action.child.id.includes("-") && child.id.includes("-")) {
                      return child.id === action.child.id;
                    }
                    if (child._id === undefined) {
                      if (action.alType !== undefined && action.altType.match(/Grouping/i)) {
                        return child.rptId === action.child.id;
                      }
                      return child.id === action.child.id;
                    }
                    return child._id === action.child.id;
                  });

                  if (childActive.length === 0) {
                    // tab.activeChildren[pIdx].children.push(action.child);
                    if (tab.activeChildren[pIdx].activeChildren !== undefined) {
                      tab.activeChildren[pIdx].activeChildren.push(action.child);
                    } else {
                      tab.activeChildren[pIdx].activeChildren = [action.child];
                    }
                    selectedTab[2] = tab.activeChildren[pIdx].children.length - 1;
                  } else {
                    if (action.child.path !== undefined && action.child.path.split("/").length > 3) {
                      let pathArr = action.child.path.split("/");

                      let parPath = pathArr[0] + "/" + pathArr[1] + "/" + pathArr[2] + "/";
                      tab.activeChildren[pIdx].activeChildren.forEach((child, cIdx) => {
                        if (child.path === parPath) {
                          selectedTab[3] = cIdx;
                        }
                      });
                    } else {
                      if (tab.activeChildren[pIdx].activeChildren === undefined) {
                        selectedTab[2] = 0;
                      } else {
                        selectedTab[2] = tab.activeChildren[pIdx].activeChildren.length;
                      }
                    }
                    if (gParentPathArr.length > 3) {
                      let cIdx = -1;
                      tab.activeChildren[pIdx].activeChildren.forEach((item, idx) => {
                        if (item.path === parentPath) {
                          cIdx = idx;
                        }
                      });
                      if (tab.activeChildren[pIdx].activeChildren[cIdx].activeChildren !== undefined) {
                        tab.activeChildren[pIdx].activeChildren[cIdx].activeChildren.push(action.child);
                      } else {
                        tab.activeChildren[pIdx].activeChildren[cIdx].activeChildren = [action.child];
                      }
                      selectedTab[3] = tab.activeChildren[pIdx].activeChildren[cIdx].activeChildren.length - 1;
                    } else if (tab.activeChildren[pIdx].activeChildren !== undefined) {
                      //TODO: tab.activeChildren may be undefined
                      tab.activeChildren[pIdx].activeChildren.push(action.child);
                    } else if (gParentPathArr.length <= 3) {
                      tab.activeChildren[pIdx].activeChildren = [action.child];
                    }
                    if (action.child.type !== undefined && action.child.type === "fileGroupings") {
                      selectedTab[2] = tab.activeChildren[pIdx].activeChildren.length - 1;
                    }
                    //TODO change to the proper selected tab
                  }
                } else {
                  //A brand new item to the nav menu
                  if (action.child.type === "grouping") {
                    parent.children = [];
                  } else {
                    if (gParentPathArr.length > 3) {
                      parent.activeChildren = cloneDeep(parent.children);
                      parent.activeChildren = parent.activeChildren.filter(item => {
                        return item.path === parentPath;
                      });
                      parent.activeChildren[0].activeChildren = [action.child];
                    } else {
                      parent.activeChildren = [action.child];
                    }
                  }
                  let activeParentIndex;
                  const parentActive = tab.activeChildren.find((item, idx) => {
                    if (item.path && parent.path) {
                      if (item.path === parent.path) {
                        activeParentIndex = idx;
                      }
                      return item.path === parent.path;
                    }
                    return false;
                  });
                  if (parentActive) {
                    if (action.child.path && action.child.path.split("/").length === 4) {
                      let gIdx;
                      tab.activeChildren[activeParentIndex].activeChildren.forEach((item, tIdx) => {
                        if (item.path === parentPath) gIdx = tIdx;
                      });
                      if (tab.activeChildren[activeParentIndex].activeChildren[gIdx].activeChildren !== undefined) {
                        tab.activeChildren[activeParentIndex].activeChildren[gIdx].activeChildren.push(action.child);
                      } else {
                        tab.activeChildren[activeParentIndex].activeChildren[gIdx].activeChildren = [action.child];
                      }
                    } else {
                      if (tab.activeChildren[activeParentIndex].activeChildren !== undefined) {
                        tab.activeChildren[activeParentIndex].activeChildren.push(...parent.activeChildren);
                      } else {
                        tab.activeChildren[activeParentIndex].activeChildren = parent.activeChildren;
                      }
                    }
                    console.log(tab.activeChildren[activeParentIndex].activeChildren);
                    console.log(selectedTab);
                    // selectedTab[1] = selectedTab[1] + 1;
                    selectedTab[2] = tab.activeChildren[activeParentIndex].activeChildren.length - 1;
                    console.log(selectedTab);
                  } else {
                    if (tab.activeChildren !== undefined) {
                      tab.activeChildren.push(parent);
                    } else {
                      tab.activeChildren = [parent];
                    }
                    selectedTab[1] = selectedTab[1] + 1;
                    selectedTab[2] = 0;
                  }
                }
              }
            } else {
              if (action.child.path !== undefined && action.child.path.split("/").length > 3) {
                let gParentPathArr = action.child.path.split("/");
                let gParentPath = gParentPathArr[0] + "/" + gParentPathArr[1] + "/";
                let parentPathArr = action.child.path.split("/");
                let parentPath = parentPathArr[0] + "/" + parentPathArr[1] + "/" + parentPathArr[2] + "/";
                let gParent = cloneDeep(state.tabs[selectedTab[0]].children);

                gParent = gParent.filter(item => {
                  return item.path === gParentPath;
                });
                let addNewChild = gParent[0].children.filter(gChild => {
                  return gChild.path === parentPath;
                });
                addNewChild[0].activeChildren = [action.child];
                gParent[0].activeChildren = addNewChild;

                if (tab.activeChildren.length === 0) {
                  tab.activeChildren = gParent;
                  selectedTab = [selectedTab[0], 0, 0, 0];
                } else {
                  tab.activeChildren.push(gParent[0]);
                  selectedTab = [selectedTab[0], tab.activeChildren.length, 0, 0];
                }

                // tab.activeChildren = state.tabs[selectedTab[0]];
              } else {
                if (action.child.path === undefined && action.child.paths !== undefined && action.child.paths.length > 0) {
                  action.child.path = action.child.paths[0];
                  //Remove the trailing / causes problems ala BIG-925
                  if (action.child.path[action.child.path.length - 1] === "/") {
                    action.child.path = action.child.path.slice(0, -1);
                  }
                }
                let title = action.child.name;
                if (title === undefined) {
                  title = action.child.title;
                }

                if (
                  action.child.path !== undefined &&
                  action.child.path.split("/").length >= 3 &&
                  !action.child.path.includes("+") &&
                  !title.includes("/")
                ) {
                  let ptPathArr = action.child.path.split("/");
                  let ptPath = ptPathArr[0] + "/" + ptPathArr[1] + "/";
                  let nFindAC = cloneDeep(tab.children);
                  let parentIndex = -1;
                  let childIndex = -1;
                  let gChildIndex = -1;
                  nFindAC = nFindAC.find((item, pIdx) => {
                    if (item.path === ptPath) {
                      parentIndex = pIdx;
                    }
                    return item.path === ptPath;
                  });
                  if (nFindAC === undefined) {
                    nFindAC = action.tab;
                    parentIndex = 0;
                    if (nFindAC.activeChildren === undefined) {
                      childIndex = 0;
                      nFindAC.activeChildren = [action.child];
                      selectedTab[1] = childIndex;
                    } else {
                      childIndex = nFindAC.activeChildren.length;
                      nFindAC.activeChildren.push(action.child);
                      selectedTab[1] = childIndex;
                    }
                    runSelectedIndex = false;
                  } else if (ptPathArr.length === 3) {
                    if (nFindAC.activeChildren === undefined) {
                      childIndex = 0;
                      nFindAC.activeChildren = [action.child];
                    } else {
                      childIndex = nFindAC.activeChildren.length;
                      nFindAC.activeChildren.push(action.child);
                    }

                    selectedTab = [selectedTab[0], parentIndex, childIndex];
                  } else {
                    let findGChild = cloneDeep(nFindAC.children);
                    findGChild = findGChild.find(item => {
                      return item.path === ptPathArr[0] + "/" + ptPathArr[1] + "/" + ptPathArr[2] + "/";
                    });
                    if (findGChild.activeChildren === undefined) {
                      gChildIndex = 0;
                      findGChild.activeChildren = [action.child];
                    } else {
                      gChildIndex = findGChild.activeChildren.length;
                      findGChild.activeChildren.push(action.child);
                    }
                    if (nFindAC.activeChildren === undefined) {
                      childIndex = 0;
                      nFindAC.activeChildren = [findGChild];
                    } else {
                      childIndex = nFindAC.activeChildren.length;
                      nFindAC.activeChildren.push(findGChild);
                    }
                    selectedTab = [selectedTab[0], parentIndex, childIndex, gChildIndex];
                  }
                  let nParentIndex = -1;
                  let parentExists = tab.activeChildren.filter((item, idx) => {
                    if (item.path === nFindAC.path) {
                      nParentIndex = idx;
                    }
                    return item.path === nFindAC.path;
                  });
                  if (runSelectedIndex) {
                    if (parentExists.length === 0) {
                      tab.activeChildren.push(nFindAC);
                    } else {
                      tab.activeChildren[nParentIndex].activeChildren.push(action.child);
                    }
                  }
                } else if (action.customGroup !== undefined && action.customGroup !== "") {
                  let parentIndex = -1;
                  let childIndex = -1;
                  let findParent = cloneDeep(tab.children);
                  findParent = findParent.find((item, pIdx) => {
                    if (item.id === action.child.customGroup) {
                      parentIndex = pIdx;
                    }
                    return item.id === action.child.customGroup;
                  });
                  if (findParent.activeChildren === undefined) {
                    childIndex = 0;
                    findParent.activeChildren = [action.child];
                  } else {
                    childIndex = findParent.activeChildren.length;
                    let findAChild = findParent.activeChildren.find(item => {
                      return item.id === action.child.id;
                    });
                    if (findAChild === undefined) {
                      findParent.activeChildren.push(action.child);
                    }
                  }

                  let findACParent = tab.activeChildren.find((item, idx) => {
                    if (item.id === findParent.id) {
                      parentIndex = idx;
                    }
                    return item.id === findParent.id;
                  });
                  if (findACParent === undefined) {
                    tab.activeChildren.push(findParent);
                  } else {
                    tab.activeChildren[parentIndex] = findParent;
                  }

                  selectedTab = [selectedTab[0], parentIndex, childIndex];
                } else {
                  tab.activeChildren.push(action.child);
                  if (selectedTab.length === 3) {
                    selectedTab.pop();
                  }
                }
              }
            }
          }
          //selectedTab[1] = tab.activeChildren.length - 1;
          if (runSelectedIndex) {
            if (action.child.type !== undefined && action.child.type.match(/Grouping/i)) {
              tab.activeChildren.forEach((item, idx) => {
                if (item.id === action.child.groupId || item.id === action.child.id) {
                  selectedTab[1] = idx;
                  if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
                    item.activeChildren.forEach((sChild, sIdx) => {
                      if (sChild.id === action.child.id) {
                        selectedTab[2] = sIdx;
                      }
                    });
                  }
                }
              });
            } else if (action.child.path !== undefined && action.child.path.split("/").length === 3) {
              let pathCmpArr = action.child.path.split("/");
              let pathCmp = pathCmpArr[0] + "/" + pathCmpArr[1] + "/";
              // selectedTab[1] = tab.activeChildren.length - 1;
              //removed this for BIG-877 it was causing wrong indexes for items in a pbi folder (iterating over the children
              // instead of the active children)

              if (selectedTab[2] !== undefined && action.child.type !== undefined) {
                tab.activeChildren.forEach((item, idx) => {
                  if (item.path === pathCmp) {
                    selectedTab[1] = idx;
                  }
                });
              } else if (action.child.fileType !== undefined && action.child.fileType === "folder") {
                selectedTab[2] = undefined;
              } else {
                tab.activeChildren.forEach((item, idx) => {
                  if (item.path === pathCmp) {
                    if (item.activeChildren) {
                      item.activeChildren.forEach((gItem, gIdx) => {
                        //remove the forward slash at the end of the path if it exists
                        let gItmCmp = gItem.path;
                        if (!action.child.path.endsWith("/")) {
                          gItmCmp = gItmCmp.replace(/\/$/, "");
                        }
                        if (gItmCmp === action.child.path) {
                          selectedTab[2] = gIdx;
                        }
                      });
                      // selectedTab[2] = item.activeChildren.length - 1;
                    } else {
                      selectedTab[2] = 0;
                    }
                  }
                });
              }
            } else if (action.child.path !== undefined && action.child.path.split("/").length > 3) {
              let lpathCmpArr = action.child.path.split("/");
              let lpathCmp = lpathCmpArr[0] + "/" + lpathCmpArr[1] + "/";
              let lgpathCmp = lpathCmpArr[0] + "/" + lpathCmpArr[1] + "/" + lpathCmpArr[2] + "/";
              // selectedTab[1] = tab.activeChildren.length - 1;
              tab.activeChildren.forEach((item, idx) => {
                if (item.path === lpathCmp) {
                  selectedTab[1] = idx;
                  if (lpathCmpArr[3] !== "") {
                    item.activeChildren.forEach((sChild, sIdx) => {
                      if (sChild.path === lgpathCmp) {
                        selectedTab[2] = sIdx;
                      }
                      if (sChild.id !== undefined && sChild.id === action.child.id) {
                        selectedTab[2] = sIdx;
                      } else if (sChild.activeChildren !== undefined && sChild.activeChildren.length > 0) {
                        sChild.activeChildren.forEach((gChild, gIdx) => {
                          if (gChild.path === lgpathCmp + lpathCmpArr[3]) {
                            selectedTab[3] = gIdx;
                          }
                        });
                      }
                    });
                  }
                }
              });
            } else {
              let newIdx = -1;
              tab.activeChildren.forEach((item, idx) => {
                if (action.child.id) {
                  if (action.child.id.includes("+")) {
                    if (item.id.includes("+")) {
                      if (String(item.id.split("+")[1]) === String(action.child.id.split("+")[1])) {
                        newIdx = idx;
                      }
                    } else {
                      if (String(item.id) === String(action.child.id.split("+")[1])) {
                        newIdx = idx;
                      }
                    }
                  } else if (item.id === action.child.id) {
                    newIdx = idx;
                  }
                } else if (action.child._id === item._id) {
                  newIdx = idx;
                }
              });
              if (newIdx !== -1) {
                selectedTab[1] = newIdx;
              } else {
                selectedTab[1] = tab.activeChildren.length - 1;
              }
            }
          }

          return tab;
        } else {
          return tab;
        }
      });

      // console.log(selectedTab);
      return {
        ...state,
        tabs: addChildTabs,
        searchTabs: addChildTabs,
        selectedTab
      };
    case REMOVE_CHILD:
      let newActiveChildren = [];
      let cTabs = state.tabs;
      let rmParentPathArr = [];
      let isCustomApp = false;
      if (action.child.path !== undefined) {
        if (action.child.path.split("/")[0] === action.tab.workspaceName) {
          rmParentPathArr = action.child.path.split("/");
        } else {
          rmParentPathArr = [action.tab.workspaceName];
          isCustomApp = true;
        }
      }

      newActiveChildren = action.tab.activeChildren.filter((item, idx) => {
        if (isCustomApp) {
          if (item.type === "grouping") {
            if (item.activeChildren === undefined) {
              return false;
            }
            item.activeChildren = item.activeChildren.filter(child => {
              return child.id !== action.child.id;
              // if(child.activeChildren.length ===0)
            });
            return item.activeChildren.length > 0;
          }
          return item.id !== action.child.id;
        }
        if (action.child.type === undefined && action.child.fileType === "folder") {
          if (rmParentPathArr.length > 3) {
            if (item.activeChildren !== undefined) {
              item.activeChildren = item.activeChildren.filter(child => {
                return child.path !== action.child.path;
                // if(child.activeChildren.length ===0)
              });
            }

            return item;
          } else {
            return item.path !== action.child.path;
          }
        } else if (action.child.type === "tableau") {
          if (action.child.title === undefined || action.child.title === "") {
            return item.title !== action.child.text;
          }
          return item.title !== action.child.title;
        } else if (
          action.child.type.includes("Groupings") ||
          (action.child.path !== undefined && action.child.path.split("/").length >= 3)
        ) {
          if (item.children === undefined || item.children.length === 0) {
            return true;
          } else {
            if (item.activeChildren !== undefined) {
              item.activeChildren = item.activeChildren.filter(child => {
                if (rmParentPathArr.length > 3) {
                  if (child.activeChildren !== undefined) {
                    child.activeChildren = child.activeChildren.filter(gChild => {
                      return gChild.path !== action.child.path;
                    });
                  }
                  // if(child.activeChildren.length ===0)
                }
                return child.id !== action.child.id;
              });
            }
            if (item.children.length > 0) {
              return true;
            } else {
              return true;
            }
          }
        } else if (item.id !== undefined && item.id.includes("+")) {
          if (action.child.id.includes("+")) {
            return item.id !== action.child.id;
          }
          return item.id.split("+")[1] !== action.child.id;
        }
        if (item.children !== undefined && item.children.length > 0) {
          // if (item.children.length > 0) {
          //   item.children = item.children.filter(child => {
          //     return child.id !== action.child.id;
          //   });
          // }
          return item.id !== action.child.id;
        } else {
          return item.id !== action.child.id;
        }
      });
      cTabs = cTabs.map(tab => {
        if (tab.workspaceId === action.tab.workspaceId) {
          tab.activeChildren = newActiveChildren;
          //tab.children = newActiveChildren;
        }
        return tab;
      });

      return {
        ...state,
        tabs: cTabs,
        searchTabs: cTabs
      };
    case SET_SEARCH_TAB:
      if (action.filter === "") {
        return {
          ...state,
          searchTabs: state.tabs,
          filter: action.filter
        };
      } else {
        let sNewTabs = cloneDeep(state.tabs);
        let payload = action.payload.filter(tabItem => {
          return tabItem !== null;
        });
        sNewTabs = sNewTabs.map(item => {
          if (item.workspaceId === state.tabs[state.selectedTab[0]].workspaceId) {
            item.children = payload;
          }
          return item;
        });

        return { ...state, searchTabs: sNewTabs, filter: action.filter };
      }
    case GET_DISP_RPTS:
      return { ...state, getNewDisplayRpts: action.payload };
    default:
      return state;
  }
};

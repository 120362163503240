// // /src/components/mdm/route.js
// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import { Switch, Redirect, Route } from "react-router-dom";
// import DataManagement from "components/mdm/components/dataManagement";
// import { useQueryClient } from "@tanstack/react-query";

// var queryClient = useQueryClient();
// class MdmRouter extends Component {
//   render() {
//     const { match } = this.props;
//     return (
//       <div
//         className="reports-router-div"
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           // position: "fixed",
//           // top: "100px",
//           paddingTop: "42px",
//           height: "100%",
//           //minHeight: "100%",
//           width: "100%",
//           // paddingBottom: "130px",
//           paddingLeft: "10px",
//           paddingRight: "10px"
//         }}>
//         <Switch>
//           <Redirect from="/mdm" exact to={"/mdm/dataManagement/"} />
//           <Route
//             path={match.path + "/dataManagement"}
//             render={props => <DataManagement queryClient={queryClient} {...props} />}
//           />
//         </Switch>
//       </div>
//     );
//   }
// }

// export default withRouter(MdmRouter);

// /src/components/mdm/routes.js
import React from "react";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import DataManagement from "components/mdm/components/dataManagement";
import { useQueryClient } from "@tanstack/react-query";

function MdmRouter({ match }) {
  // Hooks are allowed in function components
  const queryClient = useQueryClient();

  return (
    <div
      className="reports-router-div"
      style={{
        display: "flex",
        flexDirection: "column",
        // position: "fixed",
        // top: "100px",
        paddingTop: "42px",
        height: "100%",
        //minHeight: "100%",
        width: "100%",
        // paddingBottom: "130px",
        paddingLeft: "10px",
        paddingRight: "10px"
      }}>
      <Switch>
        <Redirect from="/mdm" exact to={"/mdm/dataManagement/"} />
        <Route
          path={match.path + "/dataManagement"}
          render={props => <DataManagement queryClient={queryClient} {...props} />}
        />
      </Switch>
    </div>
  );
}

export default withRouter(MdmRouter);

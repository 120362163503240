// /src/index.js

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./index.css";

import * as serviceWorker from "./serviceWorker";
import ReduxThunk from "redux-thunk";
//import { createLogger } from "redux-logger";
import rootReducer from "reducers/rootReducer";
import { TabsRouter } from "./routes";
import CacheBuster from "./cacheBuster";

import "bootstrap/dist/css/bootstrap.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000 // 10 minutes
    }
  }
});
function configureStore(initialState = {}) {
  //const reduxMiddleware = applyMiddleware(ReduxThunk, createLogger());
  const reduxMiddleware = applyMiddleware(ReduxThunk);
  return createStore(rootReducer, initialState, reduxMiddleware);
}
let configuredStore = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={configuredStore}>
    <QueryClientProvider client={queryClient}>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return <TabsRouter store={configuredStore} />;
        }}
      </CacheBuster>
    </QueryClientProvider>
  </Provider>
);
serviceWorker.unregister();

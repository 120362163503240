// /src/components/mdm/components/dataManagement/fkCellEditor.js
import React, { Component, createRef } from "react";

export default class FKCellEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    let nValue = "";
    if (this.isObject(props.value) && props.value.name !== null) {
      nValue = props.value.name;
    } else if (this.isObject(props.value)) {
      nValue = "";
    } else {
      nValue = props.value;
    }

    this.state = {
      value: nValue
    };
  }

  isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
  }
  componentDidMount() {
    this.inputRef.current.focus();
  }

  /* Component Editor Lifecycle methods */

  // the final value to send to the grid, on completion of editing
  getValue() {
    // this simple editor doubles any value entered into the input
    return this.state.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }
  handleBlur = () => {
    console.log("Input lost focus with value:", this.state.value);
    if (this.params && this.params.api) {
      this.props.api.stopEditing();
    }
  };
  render() {
    return (
      <input
        style={{ width: "100%" }}
        ref={this.inputRef}
        value={this.state.value}
        onChange={event => this.setState({ value: event.target.value })}
        onBlur={this.handleBlur}
      />
    );
  }
}

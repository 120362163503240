import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "components/accucast/components/style/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { connect } from "react-redux";
import {
  setSelectedTab,
  removeTabFromNav,
  addChild,
  removeChild,
  addTabToNav,
  filterTab
} from "components/topLevelNav/reducers/topLevelNavReducer";
import { setReportGroup, setCustomGroup } from "reducers/appReducer";
import { Icon } from "react-icons-kit";
import { home3 } from "react-icons-kit/icomoon/home3";
//import { TopLevelNavItem } from "components/topLevelNav/components/topLevelNavItem";
import TopLevelParentNavItem from "components/topLevelNav/components/topLevelParentNavItem";
import { CloseReportIcon } from "lib/icons";
import "./style.css";
import { cloneDeep } from "lodash";

//import { TopLevelNavItem } from "./components/topLevelNavItem";

class TopLevelNav extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);
    this.homeClickHandler = this.homeClickHandler.bind(this);
    this.renderSubTabs = this.renderSubTabs.bind(this);
    this.childRemoveHandler = this.childRemoveHandler.bind(this);
    this.state = {
      showClose: -1,
      showCloseChild: -1
    };
  }

  goTo(route) {
    if (this.props.history.location.pathname !== `/${route}`) {
      this.props.history.replace(`/${route}`);
    }
  }

  removeHandler(event, clicked) {
    const { defaultApp } = this.props;
    event.stopPropagation();

    //if you are not removing the tab you are on do nothing

    //if (this.props.history.location.pathname.split("/")[3] !== clicked.id) {
    if (
      !this.props.history.location.pathname.includes(clicked.id) &&
      !this.props.history.location.pathname.includes(clicked.type)
    ) {
      if (clicked.parentIndex !== undefined && this.props.tabs[clicked.parentINdex].children.length > 1) {
        this.props.setSelectedTab(this.props.selectedTab[0], this.props.selectedTab[1] - 1);
      } else {
        this.props.setSelectedTab([this.props.selectedTab[0] - 1, 0]);
      }
    } else if (this.props.tabs.length === 1) {
      if (defaultApp.link !== undefined) {
        if (defaultApp.link.includes("dataManagement")) {
          let navItem = defaultApp.link;
          let dmArrGroups = this.props.apps.filter(item => {
            return item.type === "mdh";
          });
          dmArrGroups = dmArrGroups[0];
          let navItemBase = navItem.split("dataManagement/")[0];

          this.props.history.replace(navItemBase + "dataManagement/" + dmArrGroups.groups[0].ID + "+0");
          this.props.addTabToNav({
            title: "DataManagement",
            id: dmArrGroups.groups[0].ID + "+0",
            type: "mdh",
            path: "/mdm/dataManagement/" + dmArrGroups.groups[0].ID + "+0"
          });
        } else {
          this.props.history.replace(defaultApp.link);
        }
      } else {
        this.props.history.replace("/reports");
      }
      this.props.setSelectedTab([-1, -1]);
    } else {
      //If you remove an item that you have selected ... navigate to the next item to the left
      let newRptId =
        this.props.tabs
          .map(function(item) {
            return item.id;
          })
          .indexOf(clicked.id) - 1;
      if (clicked.parentIndex !== undefined && clicked.index >= 1) {
        this.props.setSelectedTab([clicked.parentIndex, clicked.index - 1]);
        if (this.props.tabs[clicked.parentIndex].children[clicked.index].type === "powerBI") {
          this.props.history.push("/powerBI/report/" + this.props.tabs[clicked.parentIndex].children[clicked.index - 1].id);
        }
      } else if (clicked.parentIndex !== undefined && clicked.index === 0) {
        this.props.setSelectedTab([clicked.parentIndex - 1, clicked.index]);
        if (this.props.tabs[clicked.parentIndex].children[clicked.index].type === "powerBI") {
          this.props.history.push("/powerBI/report/" + this.props.tabs[clicked.parentIndex - 1].children[clicked.index].id);
        }
      } else {
        //If the item removed was the first item navigate to the first item to the right
        if (newRptId === -1) {
          newRptId = 1;
        }

        this.props.history.push("/powerBI/report/" + this.props.tabs[newRptId].id);
        this.props.setSelectedTab([this.props.selectedTab[0] - 1, newRptId]);
      }
      this.props.history.push(this.props.tabs[newRptId].path);
      this.props.setSelectedTab(newRptId);
    }
    this.props.removeTabFromNav(clicked);
  }
  async clickHandler(clicked, index, type, createNew = true) {
    let updatedIdx = false;

    if (this.props.tabs[index[0]].activeChildren !== undefined) {
      if (index !== undefined && index[2] === undefined) {
        this.props.tabs[index[0]].activeChildren.forEach((item, xIdx) => {
          if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
            item.activeChildren.forEach((iChild, idx) => {
              if (iChild.id === clicked.id) {
                index[1] = xIdx;
                updatedIdx = true;
              }
            });
          } else if (item.id === clicked.id) {
            index[1] = xIdx;
            updatedIdx = true;
          }
        });
        if (!updatedIdx) {
          index[1] = this.props.tabs[index[0]].activeChildren.length;
        }
      } else if (index !== undefined && index[3] === undefined) {
        this.props.tabs[index[0]].activeChildren.forEach((item, xIdx) => {
          if (item.activeChildren !== undefined) {
            item.activeChildren.forEach((gItem, gIdx) => {
              if (gItem.id === clicked.id) {
                index[1] = xIdx;
                index[2] = gIdx;
                updatedIdx = true;
              }
            });
          }
        });
      }
      if (!updatedIdx) {
        if (index.length === 2) {
          index[1] = this.props.tabs[index[0]].activeChildren.length;
        } else if (index.length === 3) {
          const activeChild = this.props.tabs[index[0]].activeChildren[index[1]];
          if (activeChild.activeChildren !== undefined) {
            index[2] = activeChild.activeChilren.length;
          } else {
            index[2] = 0;
          }
        }
      }
    }

    var addChild = false;
    switch (type) {
      case "powerBI":
      case "fileGroupings":
        let modifiedClicked = { ...clicked };
        delete modifiedClicked.clickHandler;
        delete modifiedClicked.removeHandler;
        let id;
        if (clicked.id !== undefined) {
          if (clicked.tab === undefined) {
            //check for proper id when coming from a subfolder this can be different
            if (clicked.id.includes("+")) {
              id = clicked.id;
            } else {
              if (clicked.workspaceId === undefined) {
                clicked.tab = {
                  workspace: clicked.workspace,
                  workspaceId: clicked.workspace.workspaceId
                };
                let ctWorkspace = clicked.tab.workspaceId;
                if (ctWorkspace === undefined) {
                  let findaWS = this.props.workspaces.find(item => {
                    return clicked.tab.workspace === item._id;
                  });
                  ctWorkspace = findaWS.workspaceId;
                }
                id = ctWorkspace + "+" + clicked.id;
                //id = clicked.workspace.workspaceId + "+" + clicked.id;
              } else {
                clicked.tab = {
                  workspace: clicked.workspace,
                  workspaceId: clicked.workspaceId
                };
                id = clicked.workspaceId + "+" + clicked.id;
              }
            }
          } else {
            if (clicked.id.includes("+")) {
              id = clicked.id;
            } else {
              let ctWorkspace = clicked.tab.workspaceId;
              if (ctWorkspace === undefined) {
                let findaWS = this.props.workspaces.find(item => {
                  return clicked.tab.workspace === item._id;
                });
                ctWorkspace = findaWS.workspaceId;
              }
              id = ctWorkspace + "+" + clicked.id;
            }
          }

          modifiedClicked.combiId = id;
          //there are a handful of ways this is called which use different names for the "title"
          modifiedClicked.title = modifiedClicked.text;
          modifiedClicked.selected = true;
          if (modifiedClicked.title === undefined || modifiedClicked.title === null || modifiedClicked.title === "") {
            modifiedClicked.title = modifiedClicked.name;
          }

          this.props.history.push("/powerBI/report/" + id);
          await this.props.setSelectedTab(index);
        } else {
          //if the id is null this is a (abstract) powerbi folder we are moving over to
          // QA&+Test%20Folder%201&+Subfolder%201&+
          this.props.history.push(
            "/reports/landing/" + this.props.reportGroup + "/" + encodeURI(clicked.path).replace(/\//g, "&+")
          ); //add path

          this.props.setSelectedTab(index);
        }
        if (createNew) {
          addChild = true;

          this.props.tabs[index[0]].activeChildren.forEach(item => {
            if (item.workspaceId === modifiedClicked.workspaceId) {
              if (item.activeChildren !== undefined) {
                item.activeChildren.forEach(child => {
                  if (child.id === clicked.id) {
                    addChild = false;
                  }
                });
              }
            }
          });
          if (addChild) {
            this.props.addChild(clicked.tab, modifiedClicked);
          }
        }
        if (!addChild) {
          this.props.setSelectedTab(index);
        }
        break;
      case "files":
      case "Files":
        this.props.setSelectedTab(index);
        this.props.history.push("/files/noTab");
        break;
      case "tableau":
      case "tableauGroupings":
        let tModifiedClicked = { ...clicked };
        delete tModifiedClicked.clickHandler;
        delete tModifiedClicked.removeHandler;
        let tId;
        if (clicked.tab === undefined) {
          //check for proper id when coming from a subfolder this can be different
          if (clicked.id.includes("+")) {
            tId = clicked.id;
          } else {
            clicked.tab = {
              workspace: clicked.workspace,
              workspaceId: clicked.workspaceId
            };
            if (clicked.tab === undefined || clicked.tab.workspaceId === undefined) {
              this.props.workspaces.forEach(item => {
                if (item._id === clicked.workspace) {
                  tId = item.workspaceId + "+" + clicked.id;
                }
              });
            } else {
              tId = clicked.workspaceId + "+" + clicked.id;
            }
          }
        } else {
          if (clicked.id.includes("+")) {
            tId = clicked.id;
          } else {
            if (clicked.tab === undefined || clicked.tab.workspaceId === undefined) {
              this.props.workspaces.forEach(item => {
                if (item._id === clicked.workspace) {
                  tId = item.workspaceId + "+" + clicked.id;
                }
              });
            } else {
              let ctWorkspace = clicked.tab.workspaceId;
              if (ctWorkspace === undefined) {
                let findaWS = this.props.workspaces.find(item => {
                  return clicked.tab.workspace === item._id;
                });
                ctWorkspace = findaWS.workspaceId;
              }
              tId = ctWorkspace + "+" + clicked.id;
            }
          }
        }

        tModifiedClicked.id = tId;
        //there are a handful of ways this is called which use different names for the "title"
        tModifiedClicked.title = tModifiedClicked.text;

        if (tModifiedClicked.title === undefined || tModifiedClicked.title === null || tModifiedClicked.title === "") {
          tModifiedClicked.title = tModifiedClicked.name;
        }
        addChild = false;
        this.props.history.push("/tableau/report/" + tId);
        if (createNew) {
          addChild = true;
          var selIdx = this.props.selectedTab[0];
          if (selIdx === -1) {
            if (this.props.tabs.index !== undefined) {
              selIdx = this.props.tabs.index[0];
            } else {
              this.props.tabs.forEach((item, idx) => {
                if (item.workspaceId === clicked.tab.workspaceId) {
                  selIdx = idx;
                }
              });
            }
          }

          this.props.tabs[selIdx].activeChildren.forEach(item => {
            if (item.workspaceId === tModifiedClicked.workspaceId) {
              if (item.children !== undefined) {
                item.children.forEach(child => {
                  if (child._id === tModifiedClicked._id) {
                    addChild = false;
                  }
                });
              }
            }
          });
          if (addChild) {
            this.props.addChild(clicked.tab, tModifiedClicked);
          }
        }
        if (!addChild) {
          this.props.setSelectedTab(index);
        }
        break;
      // this.props.history.push("/tableau/report/" + clicked.id);
      // break;
      case "staticContent":
        if (clicked.tab === undefined) {
          this.props.history.push("/sc/report/" + clicked.workspaceId + "+" + clicked.id);
        } else {
          this.props.history.push("/sc/report/" + clicked.tab.workspaceId + "+" + clicked.id);
        }
        break;
      case "mdh":
        this.props.history.push("/mdm/dataManagement/" + clicked.id);
        break;
      case "accubase":
        this.props.history.push("/accubase");
        break;
      case "userManagement":
        this.props.history.push("/userManagement");
        break;
      case "reportSubscriptions":
        this.props.history.push("/reportSubscriptions");
        break;
      case "acculift":
        this.props.history.push("/acculift");
        break;
      case "powerBIMgmt":
        this.props.history.push("/powerBIMgmt");
        break;
      case "promoOpt":
        this.props.history.push("/promoOpt");
        break;
      case "grouping":
        this.props.setSelectedTab(index[0], index[1], 0);
        this.props.setCustomGroup(clicked.id);
        this.props.history.push("/reports/landing/" + this.props.reportGroup + "/" + clicked.id);
        break;
      case "tab":
        this.props.setSelectedTab(index);
        this.props.setReportGroup(clicked.workspaceId);
        this.props.setCustomGroup("");
        this.props.history.push("/reports/landing/" + clicked.workspaceId);

        break;
      case "folder":
        let rg = clicked.workspaceId;
        if (clicked.workspaceId === undefined) {
          let findThis = clicked.path.split("/")[0];
          const ws = this.props.workspaces.find(item => {
            return item.name === findThis;
          });
          this.props.setReportGroup(ws.workspaceId);
          rg = ws.workspaceId;
        } else {
          this.props.setReportGroup(clicked.workspaceId);
        }
        this.props.setSelectedTab(index);
        this.props.history.push("/reports/landing/" + rg + "/" + encodeURI(clicked.path).replace(/\//g, "&+"));

        this.props.setCustomGroup(clicked.path.replace(/\//g, "&+"));

        break;
      // this.props.setCustomGroup("");
      default:
        this.props.setSelectedTab(index);
        this.props.history.push(
          "/reports/landing/" + this.props.reportGroup + "/" + encodeURI(clicked.path).replace(/\//g, "&+")
        );
        if (type === undefined) {
          this.props.setCustomGroup(clicked.path.replace(/\//g, "&+"));
        }

        break;
    }
    if (index !== undefined && index[1] === undefined) {
      let idx = 0;
      for (let item of this.props.tabs[index[0]].activeChildren) {
        if (item.workspaceId === clicked.workspaceId) {
          index[1] = idx;
        }
        idx = idx + 1;
      }
    }
    if (!createNew) {
      this.props.setSelectedTab(index);
    }
  }
  homeClickHandler() {
    const { defaultApp, homePage } = this.props;
    this.props.setReportGroup("");
    this.props.setCustomGroup("");
    this.props.setSelectedTab([-1, -1]);
    var defaultFound;

    if (homePage !== undefined && homePage !== null && Object.entries(homePage).length > 0) {
      if (homePage.link.includes("report")) {
        this.props.history.push("/reports/landing");
        this.props.setCustomGroup("");
        this.props.setReportGroup("");
      } else {
        this.props.history.push(homePage.link);
      }
    } else if (defaultApp === undefined) {
    } else if (defaultApp.link !== undefined) {
      if (defaultApp.link.includes("/landing") && this.props.workspaces.length > 0 && defaultApp.link.split("/").length > 3) {
        defaultFound = this.props.workspaces.filter(item => {
          return defaultApp.name.toLowerCase() === item.name.toLowerCase();
        });
      } else {
        defaultFound = this.props.apps.filter(item => {
          if (item.link === undefined) {
            return defaultApp.name.toLowerCase() === item.type.toLowerCase();
          } else {
            return defaultApp.link.includes(item.link);
          }
        });
      }
      if (defaultFound.length > 0) {
        if (defaultApp.link.includes("report")) {
          this.props.history.push("/reports/landing");
          this.props.setCustomGroup("");
          this.props.setReportGroup("");
        } else if (defaultApp.link.includes("dataManagement")) {
          let navItem = defaultApp.link;
          let dmArrGroups = this.props.apps.filter(item => {
            return item.type === "mdh";
          });
          dmArrGroups = dmArrGroups[0];
          let navItemBase = navItem.split("dataManagement/")[0];

          this.props.history.replace(navItemBase + "dataManagement/" + dmArrGroups.groups[0].ID + "+0");
        } else {
          this.props.history.replace(defaultApp.link);
        }
      } else if (this.props.apps.length > 0) {
        if (this.props.apps[0].type === "powerBI" || this.props.apps[0].type === "tableau") {
          this.props.history.replace("/reports");
        } else {
          this.props.history.replace(this.props.apps[0].link);
        }
      } else {
        this.props.history.replace("/noPermissions");
      }
    } else {
      let rptsFound = this.props.apps.filter(item => {
        if (item.link !== undefined) {
          return item.link.includes("/reports");
        } else {
          return item.type === "reporting";
        }
      });
      if (rptsFound.length > 0) {
        this.props.history.replace("/reports");
      } else if (this.props.apps.length > 0) {
        if (this.props.apps[0].type === "powerBI" || this.props.apps[0].type === "tableau") {
          this.props.history.replace("/reports");
        } else {
          this.props.history.replace(this.props.apps[0].link);
        }
      } else {
        this.props.history.replace("/noPermissions");
      }
    }
  }
  renderTabs() {
    const { tabs, selectedTab, searchTabs } = this.props;

    if (tabs.length !== 0) {
      let baseApps = this.props.apps.filter(item => {
        if (item.inheritBaseApp === null || item.inheritBaseApp === undefined) {
          return false;
        }
        return item.inheritBaseApp;
      });
      let baseAppsArr = cloneDeep(baseApps);
      baseAppsArr = baseAppsArr.map(item => {
        return item.type;
      });

      return searchTabs.map((tab, index) => {
        // if (tab.workspaceId !== undefined) {
        if (baseAppsArr.includes(tab.type)) {
          baseApps.forEach(item => {
            if (item.type === tab.type) {
              tab.icon = item.icon;
            }
          });
        }
        if (tab.children === undefined) {
          if (tab.type === "powerBI" || tab.type === "tableau" || tab.type === "customApplication") {
            let nTab = this.props.workspaces.filter(item => {
              if (item.workspaceId === undefined) {
                return item._id === this.props.activeWS;
              } else {
                return item.workspaceId === this.props.activeWS;
              }
            });
            if (nTab.length > 0) {
              tab.children = nTab[0].reports;
            }
          } else {
            return (
              <TopLevelParentNavItem
                tab={tab}
                tabs={tabs}
                key={index}
                index={index}
                activeWS={this.props.activeWS}
                workspaces={this.props.workspaces}
                selectedTab={this.props.selectedTab}
                parentSelected={selectedTab[0] === index}
                clickHandler={this.clickHandler}
                removeHandler={this.childRemoveHandler}
                apps={this.props.apps}
                filterTab={this.props.filterTab}
              />
            );
          }
        }

        return (
          <TopLevelParentNavItem
            tab={tab}
            tabs={tabs}
            key={index}
            index={index}
            activeWS={this.props.activeWS}
            workspaces={this.props.workspaces}
            selectedTab={this.props.selectedTab}
            parentSelected={selectedTab[0] === index}
            clickHandler={this.clickHandler}
            removeHandler={this.childRemoveHandler}
            apps={this.props.apps}
            filterTab={this.props.filterTab}
          />
        );
      });
    } else return;
  }
  childRemoveHandler(e, child) {
    const { tabs, selectedTab } = this.props;

    // if (tabs.length === 1) {

    // console.log(selectedTab);
    // console.log(selectedTab[2] !== undefined && selectedTab[2] !== -1);
    if (child.type === "grouping") {
      this.props.setSelectedTab([selectedTab[0], -1]);
      this.props.setCustomGroup("");
      this.props.history.push("/reports/landing/" + tabs[selectedTab[0]].workspaceId);
      // this.props.history.replace("/reports/landing/" + this.props.reportGroup);
      // this.props.setSelectedTab([selectedTab[0], -1]);
    } else if (child.type === undefined && child.fileType === "folder") {
      if (child.path.split("/").length > 3) {
        let newCgroupArr = child.path.split("/");
        let newCGroup = newCgroupArr[0] + "&+" + newCgroupArr[1] + "&+";
        this.props.history.push("/reports/landing/" + this.props.reportGroup + "/" + newCGroup);
        this.props.setCustomGroup(newCGroup);
        this.props.setSelectedTab([selectedTab[0], selectedTab[1], -1, -1]);
      } else {
        this.props.history.push("/reports/landing/" + this.props.reportGroup);
        this.props.setCustomGroup("");
        this.props.setSelectedTab([selectedTab[0], -1, -1]);
      }
    } else if (selectedTab[2] !== undefined && selectedTab[2] !== -1) {
      if (selectedTab[3] !== undefined && selectedTab[3] !== -1) {
        if (tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].activeChildren.length === 1) {
          this.props.setCustomGroup(tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].path);
          this.props.history.push(
            "/reports/landing/" +
              this.props.reportGroup +
              "/" +
              encodeURI(
                this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].path
              ).replace(/\//g, "&+")
          );
          this.props.setSelectedTab([selectedTab[0], selectedTab[1], selectedTab[2]]);
        } else {
          this.props.history.push(
            "/reports/landing/" +
              this.props.reportGroup +
              "/" +
              this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].activeChildren[0].id
          );
          this.props.setSelectedTab([selectedTab[0], selectedTab[1], selectedTab[2], selectedTab[3] - 1]);
        }
      } else {
        if (
          tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]]._id === child._id &&
          tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren.length <= 1
        ) {
          if (this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].id === undefined) {
            this.props.setCustomGroup(this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].path.replace(/\//g, "&+"));
            this.props.history.push(
              "/reports/landing/" +
                this.props.reportGroup +
                "/" +
                encodeURI(this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].path).replace(/\//g, "&+")
            );
          } else {
            this.props.setCustomGroup(this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].id);
            this.props.history.push(
              "/reports/landing/" +
                this.props.reportGroup +
                "/" +
                this.props.tabs[selectedTab[0]].activeChildren[selectedTab[1]].id
            );
          }
        }

        let rmvIdx = [-1, -1];
        tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren.forEach((item, idx) => {
          if (child.id.includes("+")) {
            let parentId = child.id.split("+")[0];
            let rptId = child.id.split("+")[1];
            let workspaceId;
            try {
              workspaceId = item.workspaceId;
              if (workspaceId === undefined) {
                workspaceId = item.tab.workspaceId;
              }
            } catch (err) {
              console.log(err);
            }
            if (parentId === workspaceId) {
              rmvIdx[0] = idx;
              if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
                item.activeChildren.forEach((child, cIdx) => {
                  if (child._id === rptId) {
                    rmvIdx = [idx, cIdx];
                  }
                });
              }
            }
          }
          if (item.id !== undefined) {
            if (item.id.includes("+")) {
              if (item.id.split("+")[1] === child.id) {
                rmvIdx = [selectedTab[0], idx];
                // rmvIdx[1] = idx;
              }
            } else if (item.id === child.id) {
              rmvIdx = [selectedTab[0], idx];
              // rmvIdx[1] = idx;
            }
          } else {
            if (item.path === child.path) {
              rmvIdx = [selectedTab[0], idx];
              // rmvIdx[1] = idx;
            }
          }
        });

        //if you are removing the tab you are on and its the first tab
        if (selectedTab[2] === rmvIdx[0] && rmvIdx[0] === 0) {
          let clickedItem = tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[0];
          if (clickedItem.workspaceId === undefined) {
            clickedItem.workspaceId = tabs[selectedTab[0]].workspaceId;
          }
          if (tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren.length > 1) {
            selectedTab[2] = 1;
            this.clickHandler(clickedItem, [selectedTab[0], selectedTab[1], 1], child.type, false);
          }
        } else if (selectedTab[2] === rmvIdx[0]) {
          //you are removing the tab you are on and its not the first tab
          //the tab we are moving to is a grouping so set the index appropriately
          if (tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1].type === "grouping") {
            let childIdx = 0;

            if (tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1].children.length === 0) {
              //click into the group landing page
              childIdx = -1;
              this.clickHandler(
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1],
                [selectedTab[0], selectedTab[1], rmvIdx[0] - 1, childIdx],
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1].type,
                false
              );
            } else {
              //click into the first group report
              this.clickHandler(
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1],
                [selectedTab[0], selectedTab[1], rmvIdx[0] - 1, childIdx],
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1].children[childIdx].type,
                false
              );
            }
          } else {
            this.clickHandler(
              tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[rmvIdx[0] - 1],
              [selectedTab[0], selectedTab[1], rmvIdx[0] - 1],
              child.type,
              false
            );
          }
        } else if (rmvIdx[0] < selectedTab[1]) {
          this.props.setSelectedTab([selectedTab[0], selectedTab[1], selectedTab[2] - 1]);
        }
        this.props.setSelectedTab([selectedTab[0], selectedTab[1], selectedTab[2] - 1]);
      }

      // this.clickHandler(
      //   tabs[selectedTab[0]].activeChildren[0],
      //   [selectedTab[0], 0],
      //   child.type,
      //   false
      // );
    } else if (tabs[selectedTab[0]].activeChildren.length === 1) {
      this.props.setSelectedTab([selectedTab[0], -1, -1]);
      this.props.history.replace("/reports/landing/" + this.props.reportGroup);
    } else {
      let rmvIdx = [-1, -1];
      tabs[selectedTab[0]].activeChildren.forEach((item, idx) => {
        if (child.id.includes("+")) {
          let parentId = child.id.split("+")[0];
          let rptId = child.id.split("+")[1];
          let workspaceId;
          try {
            workspaceId = item.workspaceId;
            if (workspaceId === undefined) {
              workspaceId = item.tab.workspaceId;
            }
          } catch (err) {
            console.log(err);
          }
          if (parentId === workspaceId) {
            rmvIdx[0] = idx;
            if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
              item.activeChildren.forEach((child, cIdx) => {
                if (child._id === rptId) {
                  rmvIdx = [idx, cIdx];
                }
              });
            }
          }
        }
        if (item.id !== undefined) {
          if (item.id.includes("+")) {
            if (item.id.split("+")[1] === child.id) {
              rmvIdx[0] = idx;
            }
          } else if (item.id === child.id) {
            rmvIdx[0] = idx;
          }
        } else {
          let ptPathArr = child.path.split("/");
          let ptPath = ptPathArr[0] + "/" + ptPathArr[1] + "/";
          if (item.path === ptPath) {
            rmvIdx[0] = idx;
          }
        }
      });

      //if you are removing the tab you are on and its the first tab
      if (selectedTab[1] === rmvIdx[0] && rmvIdx[0] === 0) {
        this.clickHandler(tabs[selectedTab[0]].activeChildren[1], [selectedTab[0], 0], child.type, false);
      } else if (selectedTab[1] === rmvIdx[0]) {
        //you are removing the tab you are on and its not the first tab
        //the tab we are moving to is a grouping so set the index appropriately
        if (tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1].type === "grouping") {
          let childIdx = 0;

          if (tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1].children.length === 0) {
            //click into the group landing page
            childIdx = -1;
            this.clickHandler(
              tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1],
              [selectedTab[0], rmvIdx[0] - 1, childIdx],
              tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1].type,
              false
            );
          } else {
            //click into the first group report
            this.clickHandler(
              tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1],
              [selectedTab[0], rmvIdx[0] - 1, childIdx],
              tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1].children[childIdx].type,
              false
            );
          }
        } else {
          this.clickHandler(
            tabs[selectedTab[0]].activeChildren[rmvIdx[0] - 1],
            [selectedTab[0], rmvIdx[0] - 1],
            child.type,
            false
          );
        }
      } else if (rmvIdx[0] < selectedTab[1]) {
        this.props.setSelectedTab([selectedTab[0], selectedTab[1] - 1]);
      }
    }
    // }

    this.props.removeChild(tabs[selectedTab[0]], child);
  }
  renderCloseIcons(child, showDelimiter, showPipe) {
    let delim = ">";
    if (showPipe) {
      delim = "|";
    }

    if (child.id === undefined) {
      if (this.state.showClose === child.path) {
        return (
          <div
            onClick={e => {
              this.childRemoveHandler(e, child);
            }}
            className="close-icon-div">
            <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />

            {showDelimiter && <div className="delimiter-style delimiter-hover">{delim}</div>}
          </div>
        );
      } else {
        return this.renderDelimiter(showDelimiter, delim);
      }
    } else if (this.state.showClose === child.id) {
      return (
        <div
          onClick={e => {
            this.childRemoveHandler(e, child);
          }}
          className="close-icon-div">
          <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />

          {showDelimiter && <div className="delimiter-style delimiter-hover">{delim}</div>}
        </div>
      );
    } else {
      return this.renderDelimiter(showDelimiter, delim);
    }
  }
  renderGroupingChilds(children, selectedTab, parentSelected, subFolder = false) {
    let divId;
    let tSelected = false;
    let tSelectedNonState = false;
    let sIdx = 2;

    return children.map((child, idx) => {
      let showDelimiter = false;
      if (children.length - 1 === idx) {
        showDelimiter = true;
      }

      if (parentSelected) {
        if (selectedTab[sIdx] === undefined || selectedTab[sIdx] === -1)
          if (idx === selectedTab[sIdx]) {
            tSelected = true;
          } else {
            tSelected = false;
          }
        else if (child.activeChildren && child.activeChildren.length > 0) {
          if (idx === selectedTab[sIdx]) {
            tSelectedNonState = true;
          }
          tSelected = false;
        } else if (idx === selectedTab[sIdx]) {
          if (subFolder) {
            if (idx === selectedTab[sIdx + 1]) {
              tSelected = true;
            } else {
              tSelected = false;
            }
          } else {
            if (idx === selectedTab[sIdx]) {
              tSelected = true;
            } else {
              tSelected = false;
            }
          }
        } else if (idx === selectedTab[sIdx + 1]) {
          tSelected = true;
        } else {
          tSelected = false;
        }
      }

      if (child.fileType === "file" || child.activeChildren === undefined || child.activeChildren.length === 0) {
        divId = child.id;
        if (divId === undefined) {
          divId = child.path;
        }
        return (
          <div
            className={"grouping-childs" + idx}
            onMouseEnter={e => {
              if (child.id === undefined) {
                this.setState({ showClose: child.path });
              } else {
                this.setState({ showClose: child.id });
              }
            }}
            onMouseLeave={e => {
              this.setState({ showClose: -1 });
            }}
            key={divId + "file"}
            style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                paddingLeft: "20px",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer"
              }}
              className={tSelected ? "hover" : "child-selected"}
              onClick={evt => {
                if (child.path !== undefined && child.path.split("/").length === 4 && child.path.split("/")[3] !== "") {
                  var fIdx = -1;
                  let firstIdx = selectedTab[1];

                  this.props.tabs[selectedTab[0]].activeChildren.forEach((item, idx) => {
                    if (item.name === child.path.split("/")[1]) {
                      firstIdx = idx;
                    }
                  });

                  // console.log(this.props.tabs);
                  this.props.tabs[selectedTab[0]].activeChildren[firstIdx].activeChildren.forEach((item, idx) => {
                    if (item.name === child.path.split("/")[2]) {
                      fIdx = idx;
                    }
                  });

                  this.clickHandler(child, [selectedTab[0], firstIdx, fIdx, idx], child.type, false);
                } else if (child.path !== undefined && child.path.split("/").length === 4) {
                  var kIdx = -1;
                  this.props.tabs[selectedTab[0]].activeChildren.forEach((item, idx) => {
                    if (item.name === child.path.split("/")[1]) {
                      kIdx = idx;
                    }
                  });

                  this.clickHandler(child, [selectedTab[0], kIdx, idx], child.type, false);
                } else if (child.index !== selectedTab[1]) {
                  // console.log("CHILD");
                  // console.log(child);
                  this.clickHandler(child, [selectedTab[0], child.index, idx], child.type, false);
                }
              }}>
              {child.title}
            </div>
            {this.renderCloseIcons(child, showDelimiter, true)}
          </div>
        );
      } else {
        divId = child.id;
        if (divId === undefined) {
          divId = child.path;
        }
        return (
          <div
            className="folderDiv"
            onMouseEnter={e => {
              if (child.id === undefined) {
                this.setState({ showClose: child.path });
              } else {
                this.setState({ showClose: child.id });
              }
            }}
            onMouseLeave={e => {
              this.setState({ showClose: -1 });
            }}
            key={divId + "folder"}
            style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                paddingLeft: "20px",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer"
              }}
              className={tSelected ? "hover" : "child-selected"}
              onClick={evt => {
                if (child.index !== selectedTab[1]) {
                  // console.log(this.props.tabs);
                  //find subfolder id
                  let parentIdx = 0;
                  let compareStr = child.path.split("/")[1];
                  this.props.tabs[selectedTab[0]].activeChildren.forEach((item, idx) => {
                    if (item.name === compareStr) {
                      parentIdx = idx;
                    }
                  });

                  // console.log("CHILD");
                  // console.log(child);
                  let cType = child.type;
                  if (cType === undefined) {
                    cType = child.fileType;
                  }
                  this.clickHandler(child, [selectedTab[0], parentIdx, idx], cType, false);
                }
              }}>
              {child.title}
            </div>
            {this.renderCloseIcons(child, showDelimiter, false)}
            {this.renderGroupingChilds(child.activeChildren, selectedTab, tSelectedNonState, true)}
          </div>
        );
      }
    });
  }
  renderDelimiter(render, delimiter) {
    if (render) {
      return <div className="delimiter-base">{delimiter}</div>;
    } else {
      return <div className="delimiter-base"></div>;
    }
  }
  renderSubTabs() {
    const { tabs, selectedTab } = this.props;

    if (tabs.length !== 0 && selectedTab[0] !== -1) {
      if (tabs[selectedTab[0]].activeChildren === undefined) {
        return;
      }
      return tabs[selectedTab[0]].activeChildren.map((child, idx) => {
        let showDelimiter = true;
        if (idx === tabs[selectedTab[0]].activeChildren.length - 1) {
          showDelimiter = false;
        }
        if (child.title === undefined || child.title === "") {
          return <div key={idx} />;
        }
        // console.log("SELECTED TAB " + selectedTab[1]);
        // console.log("INDEX " + idx);
        let tSelected = selectedTab[1] === idx;

        let justParentSelected = selectedTab[1] === idx && (selectedTab[2] === undefined || selectedTab[2] === -1);

        if (
          child.activeChildren !== undefined &&
          child.activeChildren.length > 0 &&
          (child.type === "grouping" || (child.fileType !== undefined && child.fileType === "folder"))
        ) {
          return (
            <div
              onMouseEnter={e => {
                this.setState({ showClose: child.id });
              }}
              onMouseLeave={e => {
                this.setState({ showClose: -1 });
              }}
              key={idx}
              className={"renderParent" + idx}
              style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  fontWeight: "bold"
                }}
                className={justParentSelected ? "hover topLvlNavDivFind" : "no-hover topLvlNavDivFind"}
                onClick={evt => {
                  if (child.index !== selectedTab[1]) {
                    if (child.type === undefined) {
                      this.clickHandler(child, [selectedTab[0], idx], child.fileType, false);
                    } else {
                      this.clickHandler(child, [selectedTab[0], idx], child.type, false);
                    }
                  }
                }}>
                {child.title}
              </div>
              {this.state.showClose === child.id ? (
                <div
                  onClick={e => {
                    this.childRemoveHandler(e, child);
                  }}
                  className="close-icon-div">
                  <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />
                  <div className="delimiter-style delimiter-hover">{">"}</div>
                </div>
              ) : (
                <div className="delimiter-style delimiter-base">{">"}</div>
              )}
              {this.renderGroupingChilds(child.activeChildren, selectedTab, tSelected)}
            </div>
          );
        } else {
          return (
            <div
              onMouseEnter={e => {
                this.setState({ showClose: child.id });
              }}
              onMouseLeave={e => {
                this.setState({ showClose: -1 });
              }}
              key={idx}
              className="CSD-Stuff"
              style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer"
                }}
                className={tSelected ? "hover child-selected-div" : "child-selected child-selected-div"}
                onClick={evt => {
                  if (child.index !== selectedTab[1]) {
                    if (child.workspaceId === undefined) {
                      child.workspaceId = this.props.activeWS.workspaceId;
                    }
                    this.clickHandler(child, [selectedTab[0], idx], child.type, false);
                  }
                }}>
                {child.title}
              </div>
              {this.state.showClose === child.id ? (
                <div
                  onClick={e => {
                    this.childRemoveHandler(e, child);
                  }}
                  className="close-icon-div">
                  <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />
                  {showDelimiter && <div className="delimiter-style delimiter-hover">|</div>}
                </div>
              ) : (
                this.renderDelimiter(showDelimiter, "|")
              )}
            </div>
          );
        }
      });
    } else {
      return <div className="EMPTY DIV" />;
    }
  }

  render() {
    const { tabs, selectedTab } = this.props;
    var showAppHome = false;
    if (tabs.length > 0 && selectedTab !== undefined && tabs[selectedTab[0]] !== undefined) {
      if (tabs[selectedTab[0]].type === undefined) {
        showAppHome = true;
      }
    }

    let selected = false;
    if (selectedTab[0] === -1) {
      selected = true;
    }

    return (
      <div className="ta-tln" style={{ position: "fixed", zIndex: 99, width: "100%" }}>
        <div className="ta-top-level-nav-container flex-container">
          <div className="ta-top-level-nav flex-container">
            {!(
              this.props.history.location.pathname.includes("/uploadFile") ||
              this.props.history.location.pathname.includes("/tabsFileShare")
            ) && (
              <div
                className={" ta-home-box ta-nav-icons" + (selected ? " ta-home-box-selected" : "")}
                style={{ cursor: "pointer", marginTop: "-4px" }}>
                <Icon style={{ color: "white" }} size={20} onClick={this.homeClickHandler} icon={home3} className="home-icon" />
              </div>
            )}
            {this.renderTabs()}
          </div>
        </div>

        <div
          className={"ta-tln-div-child"}
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
            paddingLeft: "10px"
          }}>
          {showAppHome ? (
            <div
              onMouseEnter={e => {
                this.setState({ showClose: "home" });
              }}
              onMouseLeave={e => {
                this.setState({ showClose: -1 });
              }}
              style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  this.props.setSelectedTab([selectedTab[0], -1]);
                  this.props.setCustomGroup("");
                  this.props.history.push("/reports/landing/" + this.props.tabs[selectedTab[0]].workspaceId);
                }}
                style={{}}
                className={"app-home " + (this.props.selectedTab[1] === -1 ? "hover" : "")}>
                App Home
              </div>
              {this.state.showClose === "home" ? (
                <div
                  onClick={e => {
                    let workspaceId;
                    this.props.removeTabFromNav(this.props.tabs[this.props.selectedTab[0]], 1);

                    this.props.setCustomGroup("");
                    if (this.props.tabs.length === 1) {
                      this.props.setSelectedTab([-1, -1]);
                      this.props.setReportGroup("");
                      this.props.history.push(this.props.defaultApp.link);
                    } else {
                      if (this.props.tabs.length > 1 && this.props.selectedTab[0] > 0) {
                        if (this.props.tabs[this.props.selectedTab[0] - 1].workspaceId === undefined) {
                          this.props.setReportGroup("");
                          this.props.history.push(this.props.tabs[this.props.selectedTab[0] - 1].path);
                        } else {
                          workspaceId = this.props.tabs[this.props.selectedTab[0] - 1].workspaceId;
                          this.props.setReportGroup(workspaceId);

                          this.props.history.push("/reports/landing/" + workspaceId);
                        }
                        this.props.setSelectedTab([this.props.selectedTab[0] - 1, -1]);
                      } else {
                        if (this.props.tabs[this.props.selectedTab[0] + 1].workspaceId === undefined) {
                          this.props.setReportGroup("");
                          this.props.history.push(this.props.tabs[this.props.selectedTab[0] + 1].path);
                        } else {
                          workspaceId = this.props.tabs[this.props.selectedTab[0] + 1].workspaceId;
                          this.props.setReportGroup(workspaceId);

                          this.props.history.push("/reports/landing/" + workspaceId);
                        }
                        this.props.setSelectedTab([this.props.selectedTab[0], -1]);
                      }
                    }
                  }}
                  className="close-icon-div">
                  <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />

                  <div className="delimiter-style delimiter-hover">| </div>
                </div>
              ) : (
                <div className="delimiter-style delimiter-base">| </div>
              )}
            </div>
          ) : null}
          {this.renderSubTabs()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedTab: state.topLevelNav.selectedTab,
  tabs: state.topLevelNav.tabs,
  searchTabs: state.topLevelNav.searchTabs,
  defaultApp: state.app.defaultApp,
  homePage: state.app.homePage,
  activeWS: state.reportList.activeWS,
  workspaces: state.reportList.workspaces,
  reportGroup: state.app.reportGroup,
  customGroup: state.app.customGroup,
  apps: state.app.apps,
  inboxId: state.app.inboxId
});
const mapDispatchToProps = dispatch => ({
  setSelectedTab(index) {
    dispatch(setSelectedTab(index));
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  removeTabFromNav(tab) {
    dispatch(removeTabFromNav(tab));
  },
  addChild(tab, child) {
    dispatch(addChild(tab, child));
  },
  removeChild(tab, child) {
    dispatch(removeChild(tab, child));
  },
  setReportGroup(group) {
    dispatch(setReportGroup(group));
  },
  filterTab(filter, tabs, selectedTab) {
    dispatch(filterTab(filter, tabs, selectedTab));
  },
  setCustomGroup(group) {
    dispatch(setCustomGroup(group));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopLevelNav));

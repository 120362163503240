// /src/components/mdm/reducers/mdmReducer.js

import axios from "axios";
import { cloneDeep } from "lodash";
import { setMdmApps } from "reducers/appReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  mdmConfig: [],
  loadingConfig: true,
  loadedConfig: false,
  loadingHydratedConfig: false,
  loadedHydratedConfig: false,
  loadedData: false,
  loadingData: false,
  data: [],
  rollBackData: [],
  error: null,
  // mdmApps: [],
  mdmAppsLoading: false,
  mdmAppsLoaded: false,
  loadingAppendData: false,
  actions: [],
  rowCount: null,
  nextRowToLoad: null,
  fullLoad: false,
  refresh: false,
  info: "",
  errorMessage: "",
  lastId: -1,
  externalFilter: [],
  externalFilterUpdate: false,
  selectedProjectIdx: -1,
  projects: [],
  mdmApps: []
};

/* -------------------- ACTION TYPES -------------------- */
const SET_CONFIG = "SET_CONFIG";
const SET_CONFIG_BEGIN = "SET_CONFIG_BEGIN";
const SET_CONFIG_ERROR = "SET_CONFIG_ERROR";
const SET_DATA = "SET_DATA";
const SET_DATA_BEGIN = "SET_DATA_BEGIN";
const ADD_DATA = "ADD_DATA";
const REMOVE_DATA = "REMOVE_DATA";
const APPEND_DATA = "APPEND_DATA";
const BEGIN_SET_DATA_APPEND = "BEGIN_SET_DATA_APPEND";
const SET_MDM_APPS_STATUS = "SET_MDM_APPS_STATUS";
const BEGIN_SET_MDM_APPS = "BEGIN_SET_MDM_APPS";
const SET_ACTIONS = "SET_ACTIONS";
const SET_FULL_LOAD = "SET_FULL_LOAD";
const SET_REFRESH = "SET_REFRESH";

const SET_INFO_DIALOG = "SET_INFO_DIALOG";
const SET_ERROR_DIALOG = "SET_ERROR_DIALOG";
const SET_EXT_FILTER = "SET_EXT_FILTER";
const SET_EXT_FILTER_UPDATE = "SET_EXT_FILTER_UPDATE";
const SET_LOADED_DATA_STATUS = "SET_LOADED_DATA_STATUS";
const DUPLICATE_ROW = "DUPLICATE_ROW";
const MERGE_HYDRATED_CONFIG = "MERGE_HYDRATED_CONFIG";
const SET_NEXT_ROW_TO_LOAD = "SET_NEXT_ROW_TO_LOAD";
/* -------------------- ACTION CREATORS -------------------- */

// const fetchReportListSuccess = () => {
//   return { type: FETCH_REPORT_LIST_SUCCESS };
// };
const setConfig = config => {
  return { type: SET_CONFIG, payload: config };
};
const setConfigBegin = () => ({
  type: SET_CONFIG_BEGIN
});
const setHydrateConfigBegin = () => ({
  type: "SET_HYDRATE_CONFIG_BEGIN"
});
const setHydrateConfigSuccess = () => ({
  type: "SET_HYDRATE_CONFIG_SUCCESS"
});
const setConfigError = error => {
  return { type: SET_CONFIG_ERROR, payload: error };
};
const setData = (data, rowCount, nextRow) => {
  return { type: SET_DATA, payload: data, rowCount, nextRow };
};
const setActions = actions => {
  return { type: SET_ACTIONS, payload: actions };
};
const setDataBegin = () => ({
  type: SET_DATA_BEGIN
});

// const addData = row => {
//   return { type: ADD_DATA, payload: row };
// };
const removeData = rows => {
  return { type: REMOVE_DATA, payload: rows };
};
const appendData = (rows, nextRow) => {
  return { type: APPEND_DATA, payload: rows, nextRow };
};
const setMdmAppsStatus = apps => {
  return { type: SET_MDM_APPS_STATUS, payload: apps };
};
const setGetAppBegin = () => ({
  type: BEGIN_SET_MDM_APPS
});
const setDataAppendBegin = () => ({
  type: BEGIN_SET_DATA_APPEND
});
const reducerSetFullLoad = status => ({
  type: SET_FULL_LOAD,
  payload: status
});
const reducerSetExternalFilter = filter => ({
  type: SET_EXT_FILTER,
  payload: filter
});
const reducerSetExternalFilterUpdate = status => ({
  type: SET_EXT_FILTER_UPDATE,
  payload: status
});
const reducerSetLoadedData = status => ({
  type: SET_LOADED_DATA_STATUS,
  payload: status
});
const reducerSetRefresh = status => ({
  type: SET_REFRESH,
  payload: status
});
const setInfoDialog = message => ({
  type: SET_INFO_DIALOG,
  payload: message
});
const setErrorDialog = message => ({
  type: SET_ERROR_DIALOG,
  payload: message
});
export const duplicateRow = newRow => ({
  type: DUPLICATE_ROW,
  payload: newRow
});
export const setNextRowToLoad = count => ({
  type: SET_NEXT_ROW_TO_LOAD,
  payload: count
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const setExternalFilter = filter => {
  return dispatch => {
    dispatch(reducerSetExternalFilter(filter));
  };
};
export const doSetErrorDialog = message => {
  return dispatch => {
    dispatch(setErrorDialog(message));
  };
};
export const doSetInfoDialog = message => {
  return dispatch => {
    dispatch(setInfoDialog(message));
  };
};
export const setLoadedData = status => {
  return dispatch => {
    dispatch(reducerSetLoadedData(status));
  };
};
export const setExternalFilterUpdate = status => {
  return dispatch => {
    dispatch(reducerSetExternalFilterUpdate(status));
  };
};
export const setFullLoad = status => {
  return dispatch => {
    dispatch(reducerSetFullLoad(status));
  };
};
export const setRefresh = status => {
  return dispatch => {
    dispatch(reducerSetRefresh(status));
  };
};
export const getMdmConfig = (appId, projectId) => {
  return dispatch => {
    dispatch(setConfigBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getMdmConfig`,
        { appId, tableId: projectId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setConfig(response.data.config));
      })
      .catch(error => {
        console.log(error);
        dispatch(setConfigError(error.response.data.message));
      });
  };
};
export const hydrateMdmConfig = appId => {
  return dispatch => {
    dispatch(setHydrateConfigBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getFullMdmConfig`,
        { appId },
        {
          headers: { ClientToken: localStorage.getItem("clientToken") }
        }
      )
      .then(response => {
        console.log("Full hydrated config:", response);
        // Dispatch an action to store the full config in your redux store.
        //dispatch(setConfig(response.data.config));
        dispatch({ type: MERGE_HYDRATED_CONFIG, payload: response.data.config });
        dispatch(setHydrateConfigSuccess());
      })
      .catch(error => {
        console.error("Error hydrating full config", error);
        // Optionally dispatch an error action.
        dispatch(setConfigError(error.response.data.message));
      });
  };
};

export const getMdmData = (table, source) => {
  return dispatch => {
    dispatch(setDataBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getMdmData`,
        { table, start: 0 },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setData(response.data.data, response.data.rowCount, response.data.nextRowToLoad));
        dispatch(reducerSetFullLoad(false));

        // dispatch(appendMdmData(table, response.data.nextRowToLoad, 20000));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const getActions = table => {
  return dispatch => {
    //dispatch(setDataBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getActions`,
        { table },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setActions(response.data.actions));
      })
      .catch(error => {});
  };
};

export const resetError = () => {
  return dispatch => {
    dispatch(setErrorDialog(""));
  };
};
export const resetInfo = () => {
  return dispatch => {
    dispatch(setInfoDialog(""));
  };
};
export const appendMdmData = (table, start, limit = null, source = null) => {
  return dispatch => {
    dispatch(setDataAppendBegin());

    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getMdmData`,
        { table, start, limit },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(appendData(response.data.data, response.data.nextRowToLoad));
        // if (response.data.nextRowToLoad <= response.data.rowCount) {
        //   dispatch(appendMdmData(table, response.data.nextRowToLoad, 50000));
        // } else {
        //   dispatch(setFullLoad());
        // }
      })
      .catch(error => {});
  };
};

export const setMdmData = data => {
  return dispatch => {
    dispatch(setData(data));
  };
};
// export const addNewRow = row => {
//   return dispatch => {
//     dispatch(addData(row));
//     dispatch(setRefresh(true));
//   };
// };

// export const addNewRow = row => {
//   return dispatch => {
//     if (Array.isArray(row)) {
//       dispatch(addData(row));
//     } else {
//       dispatch(duplicateRow(row));
//     }
//     dispatch(setRefresh(true));
//   };
// };

export const removeRows = rows => {
  return dispatch => {
    dispatch(removeData(rows));
  };
};
// export const saveData = (table, saveObj) => {
//   return dispatch => {
//     //dispatch(setDataBegin());
//     axios
//       .post(
//         `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/saveMdmData`,
//         { table: table.saveTable, saveObj },
//         {
//           headers: {
//             ClientToken: localStorage.getItem("clientToken")
//           }
//         }
//       )
//       .then(response => {
//         dispatch(getMdmData(table.srcTableId));
//       })
//       .catch(error => {
//         console.log(error.response.data.error);
//         //dispatch(getMdmData(table.srcTableId));
//       });
//   };
// };
export const getMdmApps = () => {
  return dispatch => {
    dispatch(setGetAppBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getMdmApps`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setMdmApps(response.data.apps));
        dispatch(setMdmAppsStatus(response.data.apps));
      })
      .catch(error => {
        console.log(error);
        //dispatch(getMdmData(table.srcTableId));
      });
  };
};
function mergeConfigs(currentConfig, fullConfig) {
  if (!currentConfig || currentConfig.length === 0) return fullConfig;
  // Create a map of current config items by id
  const currentMap = {};
  currentConfig.forEach(item => {
    currentMap[item.id] = item;
  });
  // For each item in fullConfig, if we already have data for that id, use it.
  return fullConfig.map(item => {
    if (currentMap[item.id]) {
      return { ...item, data: currentMap[item.id].data || item.data };
    }
    return item;
  });
}
/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONFIG_BEGIN:
      return {
        ...state,
        loadingConfig: true,
        loadedConfig: false,
        loadingData: false,
        loadedData: false,
        error: null
      };
    case SET_CONFIG:
      return {
        ...state,
        mdmConfig: action.payload,
        loadedConfig: true,
        loadingConfig: false
      };
    case SET_CONFIG_ERROR:
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: false,
        error: action.payload,
        mdmConfig: []
      };
    case SET_DATA_BEGIN:
      return { ...state, loadingData: true, loadedData: false, error: null };
    case SET_DATA:
      let rollBackData = cloneDeep(action.payload);
      if (action.payload.length === 0) {
        return {
          ...state,
          data: action.payload,
          rollBackData,
          loadingData: false,
          loadedData: true,
          loadingAppendData: false,
          rowCount: action.rowCount,
          lastId: 0,
          nextRowToLoad: 0
        };
      } else {
        return {
          ...state,
          data: action.payload,
          rollBackData,
          loadingData: false,
          loadedData: true,
          loadingAppendData: false,
          rowCount: action.rowCount,
          lastId: action.payload[action.payload.length - 1].ID,
          nextRowToLoad: action.nextRow
        };
      }
    case APPEND_DATA:
      let newAppendData = cloneDeep(state.data);
      newAppendData = newAppendData.concat(action.payload);
      return {
        ...state,
        data: newAppendData,
        loadingAppendData: false,
        lastId: action.payload[action.payload.length - 1].ID,
        nextRowToLoad: action.nextRow,
        refresh: true
      };
    case BEGIN_SET_DATA_APPEND:
      return { ...state, loadingAppendData: true };
    case ADD_DATA:
      let newData = cloneDeep(state.data);
      let newRowCount;

      if (!Array.isArray(action.payload)) {
        newRowCount = state.data[state.data.length - 1].ID + 1;
        newData.push(action.payload);
      } else {
        if (state.data.length === 0) {
          newRowCount = action.payload.length;
        } else {
          newRowCount = state.data[state.data.length - 1].ID + action.payload.length;
        }
        action.payload.forEach(item => {
          newData.push(item);
        });
      }

      return {
        ...state,
        data: newData,
        lastId: newRowCount
      };
    case REMOVE_DATA:
      let rmData = action.payload.map(item => {
        return item.data.ID;
      });
      let modData = cloneDeep(state.data).filter(datum => {
        return !rmData.includes(datum.ID);
      });
      console.log(modData);

      return { ...state, data: modData };
    case BEGIN_SET_MDM_APPS:
      return { ...state, mdmAppsLoaded: false, mdmAppsLoading: true };
    case SET_MDM_APPS_STATUS:
      return {
        ...state,
        mdmApps: action.payload,
        mdmAppsLoaded: true,
        mdmAppsLoading: false
      };
    case SET_ACTIONS:
      return { ...state, actions: action.payload };
    case SET_FULL_LOAD:
      return { ...state, fullLoad: action.payload };
    case SET_REFRESH:
      return { ...state, refresh: action.payload };

    case SET_INFO_DIALOG:
      return { ...state, info: action.payload };
    case SET_ERROR_DIALOG:
      return { ...state, errorMessage: action.payload };
    case SET_LOADED_DATA_STATUS:
      return { ...state, loadedData: action.payload };
    case SET_EXT_FILTER:
      //let newFilter = cloneDeep(state.externalFilter);
      // if(newFilter.length === 0){
      //   newFilter = [];
      // }
      //newFilter.push(action.payload);
      return {
        ...state,
        externalFilter: action.payload,
        externalFilterUpdate: true
      };
    case SET_EXT_FILTER_UPDATE:
      return {
        ...state,
        externalFilterUpdate: action.payload
      };
    case DUPLICATE_ROW:
      return {
        ...state,
        data: [...state.data.slice(0, action.payload.index), action.payload.row, ...state.data.slice(action.payload.index)],
        lastId: state.lastId + 1,
        refresh: true
      };
    case MERGE_HYDRATED_CONFIG:
      return {
        ...state,
        // Merge the current config (which might contain the current table's config)
        // with the full hydrated config from the server.
        mdmConfig: mergeConfigs(state.mdmConfig, action.payload)
      };
    case "SET_HYDRATE_CONFIG_BEGIN":
      return {
        ...state,
        loadingHydratedConfig: true,
        loadedHydratedConfig: false
      };
    case "SET_HYDRATE_CONFIG_SUCCESS":
      return {
        ...state,
        loadingHydratedConfig: false,
        loadedHydratedConfig: true
      };
    case SET_NEXT_ROW_TO_LOAD: {
      return {
        ...state,
        nextRowToLoad: action.payload // or maybe you do rowCount if you prefer
      };
    }
    default:
      return state;
  }
};
